import store from '../redux/store';
import * as type from '../types/monitoringTypes';
import api from "../services/api";
import moment from "moment";

const generateColumns = (date) => [
    { title: 'LOC', dataIndex: 'location', key: 'location', isSort: true },
    { title: 'CNTR_NUM', dataIndex: 'cntrLineType', key: 'cntrLineType', isSort: true },
    { title: 'COMMODITY', dataIndex: 'commodity', key: 'commodity', isSort: true },
    { title: 'SET-POINT', dataIndex: 'seT_POINT', key: 'seT_POINT', isSort: true },

    ...Array.from({ length: 4 }, (_, index) => ({
        title: date.clone().add(index, "days").format("YYYY-MM-DD"),
        dataIndex: `temper_day${index + 1}`,
        key: `temper_day${index + 1}`,
        children: [
            {
                title: "AM",
                dataIndex: `temper_am_day${index + 1}`,
                key: `temper_am_day${index + 1}`,
                isSort: true,
            },
            {
                title: "PM",
                dataIndex: `temper_pm_day${index + 1}`,
                key: `temper_pm_day${index + 1}`,
                isSort: true,
            },
        ]
    })),
    //{ title: 'Save', dataIndex: "BTN", key: "btn" },
];

const addUrlParameter = (url, parameterName, valParam) => {
    if (valParam && valParam != '')
        url = url.indexOf('?') > -1 ? `${url}&${parameterName}=${valParam}` : `${url}?${parameterName}=${valParam}`;

    return url;

}

export const loadData = (selectedDate, selectedOption, locFrom, locTo, equip, showMissing) => {

    const cols = generateColumns(selectedDate);

    let state = store.getState().monitoringReducer;
    let url = showMissing == true ? `monitoring/missing/${selectedOption}/${moment(selectedDate).format('YYYY-MM-DD')}` : `monitoring/${selectedOption}/${moment(selectedDate).format('YYYY-MM-DD')}`;
    let data = null;

    if (showMissing == false) {
        if (locFrom && locFrom != '')
            data = { locationFrom: locFrom };

        if (locTo && locTo != '')
            data = data == null ? { locationTo: locTo } : { ...data, locationTo: locTo };

        if (equip && equip != '')
            data = data == null ? { equip: equip } : { ...data, equip: equip };
    }
    console.log(data)
    state = {
        ...state,
        columns: cols,
        data: [],
        selectedDate: selectedDate,
        isLoadingData: true,
        selectedOption: selectedOption ? selectedOption : 'all'
    };
    if (selectedOption && selectedDate)
        api.apiAction({
            url: url,
            label: type.GET_DATA,
            data: data,
            onSuccess: (data) => {
                console.log(data)
                state = {
                    ...state,
                    isLoadingData: false,
                    data: data
                };
                return {
                    type: type.GET_DATA,
                    payload: state,

                }
            },
            onFailure: (data) => {
                console.log(data)
                state = {
                    ...state,
                    isLoadingData: false
                };
                return {
                    type: type.GET_DATA,
                    payload: state,

                }
            },
        });

    return {
        type: type.GET_DATA,
        payload: state,

    }
}

const areTemperaturesValid = (record, originalRecord) => {
    var result = true;

    if (!originalRecord || (originalRecord && !originalRecord.id))
        return result;

    if (record && record['seT_POINT']) {
        var setPoint = record['seT_POINT'];
        var extractedNumber = setPoint.match(/[+-]?\d+(\.\d+)?/);
        var numberPart = extractedNumber ? parseFloat(extractedNumber[0]) : null;

        if (numberPart) {
            var refLow = numberPart - 5;
            var refUp = numberPart + 5;
            for (var i = 1; i <= 4; i++) {
                if ((record["temper_am_day" + i] && record["temper_am_day" + i] !== originalRecord["temper_am_day" + i] && (record["temper_am_day" + i] < refLow || record["temper_am_day" + i] > refUp)) ||
                    (record["temper_pm_day" + i] && record["temper_pm_day" + i] !== originalRecord["temper_pm_day" + i] && (record["temper_pm_day" + i] < refLow || record["temper_pm_day" + i] > refUp))) {
                    result = window.confirm("Warning: The temperature you've entered deviates by +/- 5 degrees from the Set Point. Are you sure?")
                    if (!result) {
                        record["temper_am_day" + i] = originalRecord["temper_am_day" + i];
                        record["temper_pm_day" + i] = originalRecord["temper_pm_day" + i];
                    }
                    break;
                }
            }
        }
    }

    return result;
}

export const saveData = (selectedDate, record, originalRecord) => {
    let state = store.getState().monitoringReducer;
    if (areTemperaturesValid(record, originalRecord)) {
        state = {
            ...state
        };
        state['isSavingRow' + record.id] = true;
        if (record && selectedDate)
            api.apiAction({
                url: `monitoring/save/${moment(selectedDate).format('YYYY-MM-DD')}`,
                method: 'POST',
                data: record,
                label: type.SAVE_DATA + record.id,
                onSuccess: (data) => {
                    console.log(data)
                    state = {
                        ...state
                    };
                    state['isSavingRow' + record.id] = false;
                    return {
                        type: type.SAVE_DATA,
                        payload: state,

                    }
                },
                onFailure: (data) => {
                    console.log(data)
                    state = {
                        ...state
                    };
                    state['isSavingRow' + record.id] = false;
                    return {
                        type: type.SAVE_DATA,
                        payload: state,

                    }
                },
            });
    }

    return {
        type: type.SAVE_DATA,
        payload: state,
    }
}


export const changeSelectedOption = (value) => {
    let state = store.getState().monitoringReducer;

    state = {
        ...state,
        selectedOption: value
    };
    return {
        type: type.CHANGE_OPTION,
        payload: state,
    }
};

export const changeSelectedDate = (value) => {
    let state = store.getState().monitoringReducer;
    state = {
        ...state,
        selectedDate: value
    };
    return {
        type: type.CHANGE_DATE,
        payload: state,
    }
};



export const updateValueField = (field, id, value) => {
    console.log(field);
    console.log(id);
    console.log(value);
    let state = store.getState().monitoringReducer;
    let newData = [...state.data];

    const index = newData.findIndex((item) => id === item.id);
    console.log(index)

    // update fled
    newData[index][field] = value;

    state = {
        ...state,
        data: newData,
    };

    return {
        type: type.UPDATE_ROW_DATA,
        payload: state
    }
}
