import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useLocation } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { Form as FormAntd, Button, Input, Row, Col, Divider, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import { ReloadOutlined } from '@ant-design/icons';

import Modal from 'react-bootstrap/Modal';
import authHeader from "../services/auth-header";

import AuthService from "../services/auth.service";

import _db from "../services/data-service";

//function getDate(dateTime) {
//    return `${dateTime.getDate()}-${dateTime.getMonth() + 1}-${dateTime.getFullYear()}`
//}

const PTI_FUEL = "PTI / Fuel";
const SERVICE = "Service"; 
const NOT_DONE = "NOT DONE";

const Dashboard = () => {
    //const locat = useLocation();
    //const sTerminal = locat.state?.sel;
    //const sLocation = locat.state?.lc;

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sTerminal = params.get('t');
    let sLocation = params.get('l');

    //const { sTerminal, sLocation } = useParams();
    //console.log(locat, locat.search, sTerminal, sLocation)
    //const [link, setLink] = useState('/dashboard/fuel');
    
    const user = AuthService.getCurrentUser();   
    const [terminals, setTerminals] = useState([]);
    const [location, setLocation] = useState(sLocation);

    const [isLoading, setIsLoading] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const [data, setData] = useState([]);

    //const [container, setContainer] = useState('');

    const [selected, setSelected] = useState(sTerminal);
    const handleChange = e => {
        setSelected(e.target.value);
    }

    async function populateTerminals() {
        const { data: response } = await _db.getTerminals();
        setTerminals(response);
    }

    const getUserTerminal = async () => {
        const { data: response } = await _db.getCurrentUserTerminal();
        if (sTerminal) {
            setSelected(sTerminal);
        } else
        if (response !== '') {
            setSelected(response);
        }
    }

    useEffect(() => {
        populateTerminals();
        getUserTerminal();
    }, []);

    //useEffect(() => { 
    //    setLink(`/dashboard/fuel/${selected}/${location}`);
    //    //setLink(`/dashboard/fuel?terminal=${selected}&location=${location}`);
    //}, [selected, location]);

    //Load on startup
    useEffect(() => {
        if (sLocation) getData();
    }, []);

    const getData = async () => {
        try {
            setIsLoading(true);
            setOnLoad(false);

            let URL = "/api/DashboardGetGenset";
            console.log('Dashboard Get Genset', authHeader(), selected, location);
            const response = await _db.get(URL, { headers: authHeader(), params: { terminal: selected, location: location } });
            setData(response.data);

            //console.log("data", response.data)
            
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    /* SEARCH */
    const locationChangeHandler = (e) => {
        setLocation(e.target.value.toString().toUpperCase());
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.value.length > 0) {
            getData()
        }
    }
    /* END SEARCH */

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const selectRow = (e, equip, birthDate, LifeNum, numPhases, fuel, sLine, ptiDone) => {
        //console.log(`checked = ${e.target.checked}`);
        const selectedRows = [...selectedRowKeys];
        const index = selectedRows.findIndex(w => w.container === equip);
        const row = {
            "container": equip,
            "birthDate": birthDate,
            "lifeNum": LifeNum,
            "numPhases": numPhases,
            "mecanic": user.userName,
            "fuelAmount": fuel,
            "shippingLine": sLine,
            "terminalCode": selected,
            "ptiDone": ptiDone
        };

        if (e.target.checked) {         
            if (index < 0) {
                selectedRows.push(row);
            }
        }
        else {
            selectedRows.splice(index, 1);
        }

        setSelectedRowKeys(selectedRows);
    };
    /* END SELECT ROW TO APPROVE */

    const handleApprove = async () => {
        const url = "api/dashboardapprove";

        await _db.post(url, selectedRowKeys, { headers: authHeader() })
                 .then((reponse) => {                    
                    getData(); // refresh the page
                    _db.openNotificationWithIcon('success', 'Approved succesfully!');
                 })
                 .catch((error) => {
                    console.log(error)
                    _db.openNotificationWithIcon('error', error)
                 })
    };

    return (
        <>            
            <div className="border border-5 border-secondary overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>

                <div className="container bg-secondary">
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <span className="fs-4 fw-bold text-white">DASHBOARD</span>
                        </div>
                        <div className="col-md-3 my-2 offset-md-2">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Terminal</Form.Label>
                                    <Form.Select value={selected || ''} inline="true" className="fs-7 terminal-select" size="sm" onChange={(e) => handleChange(e)}>
                                        {/* <option value="ALL">All Terminals</option> */}
                                        {terminals.map((terminal) => (<option key={terminal.terminalCode} value={terminal.terminalCode}>{terminal.terminalCode}</option>))}
                                    </Form.Select>
                                </div>
                            </div>
                        </div>  
                        <div className="col-md-2 my-2 offset-md-1">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Location</Form.Label>
                                    <Form.Control
                                        className="fs-7"
                                        maxLength={3}
                                        size="sm"
                                        type="text"
                                        name="location"
                                        value={location || ''}
                                        onChange={locationChangeHandler}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bg-white">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2 pt-3">
                            GENSET PTI - {user && user.userName}/{new Date().toDateString() /*getDate(new Date())*/}
                        </div>
                        <div className="col-md-5 col-sm-12 my-2">
                            <Button type="primary" className="rounded" disabled={!location} onClick={getData}>REFRESH</Button>
                            <Button type="primary" className="rounded ms-2">PTI MODE</Button>

                            <Link to={{ pathname: "/dashboard/fuel", search: `?t=${selected}&l=${location}`, /*state: { sel: selected, lc: location }*/ }}>
                                <Button type="primary" className="rounded ms-2 mt-2" disabled={!location}>FUEL MODE</Button>
                            </Link>                            
                        </div>
                        <div className="col-md-1 col-sm-6 my-2 text-md-center">
                        </div>

                        <div className="col-md-3 col-sm-6 my-2 text-md-end">
                            <Button type="primary" className="rounded" disabled={!selectedRowKeys.length} onClick={handleApprove}>APPROVE</Button>
                            <Button type="primary" className="rounded ms-2 mt-2">EXIT</Button>
                        </div>
                    </div>
                </div>

            </div>
            <div className="content">
                {
                    !isLoading &&
                    <div className="pt-0 mb-2">
                        <div className="border border-5 border-secondary overflow bg-secondary">
                            <table className="" aria-labelledby="tabelLabel">
                                <thead>
                                    <tr className="bg-white border border-5 border-secondary" style={{ height: '40px' }}>
                                        <th width="" className="text-center">LOC</th>
                                        <th width="" className="text-center">GENSET NO. <br/> SHIP. LINE</th>
                                        {/*<th width="" className="text-center">SHIP. LINE</th>*/}
                                        <th width="" className="text-center">MODEL <br/> SERIAL</th>
                                        {/*<th width="" className="text-center">SERIAL</th>*/}
                                        <th width="" className="text-center">LAST HOURS</th>
                                        <th width="" className="text-center">DATE LAST PTI</th>
                                        <th width="" className="text-center">PTI TYPE</th>
                                        <th width="" className="text-center">HOURS</th>
                                        <th width="" className="text-center">HEALTH</th>
                                        <th width="" className="text-center">FUEL</th>
                                        <th width="" className="text-center">OIL<br/> PRESTONE</th>
                                        {/*<th width="" className="text-center">PRESTONE</th>*/}
                                        <th width="" className="text-center">COMMENTS</th>
                                        <th width="" className="text-center">STATUS</th>
                                        <th width="" className="text-center">APPROVE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ?
                                            data.map((item) => {
                                                return (
                                                    <tr key={item.equiP_NUM} className="border border-5 border-secondary bg-white">
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.location}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.equiP_NUM} <br/> {item.line}</td>
                                                        {/*<td className="fs-6 fw-bold text-center cell-pad">{item.line}</td>*/}
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.model}<br/>{item.serial} </td>
                                                        {/*<td className="fs-6 fw-bold text-center cell-pad">{item.serial}</td>*/}
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.lasT_HOURS}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.lasT_PTI_DATE}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.ptI_TYPE ? item.ptI_TYPE : item.iN_PTI_TYPE}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.hours}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.health}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.qty}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.oil}<br />{item.prestone}</td>
                                                        {/*<td className="fs-6 fw-bold text-centercell-pad">{item.prestone}</td>*/}
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.comment}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.eQ_STATUS}</td>
                                                        {/*daM_STATUS, ooS_STATUS, holD_STATUS, notes*/}
                                                        <td className="text-center cell-pad">
                                                            <Checkbox onChange={(e) => selectRow(e, item.equiP_NUM, item.birtH_DATE, item.lifE_NUM, item.nuM_PHASES, item.qty, item.line, item.ptI_DONE)} disabled={((item.iN_PTI_TYPE === PTI_FUEL || item.iN_PTI_TYPE === SERVICE) && (item.qty <= 0 && item.pTI_DONE !== NOT_DONE)) || item.qty <=0 }></Checkbox>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={16}>
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{onLoad ? '' : 'No data found !'}</span>
                                                </td>
                                            </tr>
                                    }
                            </tbody>
                                <tfoot className="bg-secondary"></tfoot>
                            </table>
                        </div>
                    </div>
                }
                {
                    isLoading &&
                    <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                    </div>
            }

            </div>            
        </>  
    );
};

export default Dashboard