import React, { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { Form as FormAntd, Button, Input, Row, Col, Divider, Modal, notification, } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import { ReloadOutlined } from '@ant-design/icons';

import authHeader from "../services/auth-header";
import _db from "../services/data-service";

const Shift = () => {
    const locationRef = useRef(null);

    /* -------- MODAL --------- */
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        setLoading(true);
        await shiftHandler(newLocation);

        //setTimeout(() => {
            setLoading(false);
            setOpen(false);
        //}, 3000);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    /* ------ END MODAL --------- */

    const [isLoading, setIsLoading] = useState(false);
    const [onLoad, setOnLoad] = useState(true);

    const [data, setData] = useState([]);
    const [newLocation, setNewLocation] = useState(''); //new location
    const [container, setContainer] = useState(''); //Container number  (ID of the table maints)
    const [maint, setMaint] = useState([]);
    const [LifeNum, setLifeNum] = useState('');
    const [oldLocation, setOldLocation] = useState('');

    const clear = () => {
        setNewLocation('');
        setContainer('');
    }

    ////Load on startup
    //useEffect(() => {
    //    getData();
    //}, []);

    //Set focus on location input text...
    useEffect(() => {
        if (locationRef.current) {
            locationRef.current.focus({
                cursor: 'start',
            })
        }
    }, [open]);

    const getData = async () => {
        try {
            setIsLoading(true);
            setOnLoad(false);

            let URL = (locFrom === "" && locTo === "") ? ("/api/maints/") : `/api/maints/SearchByLocation?from=${locFrom}&to=${locTo}`;
            console.log('shift', authHeader());
            const response = await _db.get(URL, { headers: authHeader() });
            //, params: { from: locFrom, to: locTo } 

            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    const showModalHandler = (id, life, loc) => {        
        showModal();
        setContainer(id);
        setLifeNum(life);
        setOldLocation(loc);
        _db.get(`/api/maintByCntr/${id}`, { headers: authHeader() })
            .then((reponse) => {
                setMaint(reponse.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const shiftHandler = async (location) => {
        const url = `/api/maint/${container}`
        const data = { "cntrNum": container, "maint": { ...maint, "location": location, "shift_STATUS": 2, "put_back": 0 } }

        await _db.put(url, data.maint, { headers: authHeader(), params: { lifeNum: LifeNum, oldLocation: oldLocation } })
             .then((reponse) => {
                 //handleCancel();
                setOpen(false);
                getData();
                 clear();
                 _db.openNotificationWithIcon('success', 'Shifted succesfully!');
                //openNotificationWithIcon('success');
            })
            .catch((error) => {
                //console.log('shiftErr', error)
                _db.openNotificationWithIcon('error', error.response.data)
            })
    }

    /* SEARCH */
    const [locTo, setLocTo] = useState('');
    const locToChangeHandler = (e) => {
        setLocTo(e.target.value.toString().toUpperCase());
    }
    const [locFrom, setLocFrom] = useState('');
    const locFromChangeHandler = (e) => {
        setLocFrom(e.target.value.toString().toUpperCase());
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getData()
        }
    }
    /* END SEARCH */    

    return (
        <>
            <div className="border border-5 border-secondary overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
            <div className="container bg-secondary">
                <div className="row">
                    <div className="col-md-4 col-sm-12 my-2">
                        <span className="fs-4 fw-bold text-white"> Shift Location</span>
                    </div>
                    <div className="col-md-2 col-6 my-2">
                        <div className="row fs-6 fw-bold cell-pad">
                            <Form.Label className="col-sm-3 text-white">From</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    className="fs-7"
                                    maxLength={4}
                                    size="sm"
                                    type="text"
                                    name="locfrom"
                                    value={locFrom}
                                    onChange={locFromChangeHandler}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2 col-6 my-2">
                        <div className="row fs-6 fw-bold cell-pad">
                            <Form.Label className="col-sm-3 text-white">To</Form.Label>
                            <div className="col-sm-9">
                                <Form.Control
                                    className="fs-7"
                                    maxLength={4}
                                    size="sm"
                                    type="text"
                                    value={locTo}
                                    onChange={locToChangeHandler}
                                    onKeyPress={handleKeyPress}
                                    name="locto"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-4 my-2 d-flex justify-content-between">
                        <Button
                            style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '5px' }}
                            type="primary"
                            className="rounded"
                            icon={<ReloadOutlined />}
                            onClick={getData}>
                            &nbsp; {isLoading ? <> Loading...</> : <> Refresh</>}
                        </Button>

                        <Button type="primary"
                            className="rounded btn-sm"
                            style={{ background: "#eca52b", borderColor: "#eca52b" }}
                            onClick={() => alert("Coming soon !")}>ColinScan
                        </Button>
                    </div>
                </div>
            </div>

            </div>
            <div className="content">
                {
                    !isLoading &&
                    <div className="pt-0 mb-2">
                        <div className="border border-5 border-secondary overflow bg-secondary">
                            <table className="" aria-labelledby="tabelLabel">
                                <thead>
                                    <tr className="bg-white border border-5 border-secondary" style={{ height: '40px' }}>
                                        <th width="50" className="text-center cell-pad">LOC</th>
                                        <th width="60" className="text-center">BOX</th>
                                        <th width="120" className="text-center">CONTAINER</th>                                        
                                        <th width="" className="text-center">STATUS</th>
                                        <th width="200" className="text-center">BODY DAMAGE</th>
                                        <th width="" className="text-center">JOB STATUS</th>
                                        <th width="">COMMENT</th>
                                        <th width="100" className="text-center">PUT-BACK</th>
                                        <th width="50" className="text-center">SHIFT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ?
                                            data.map((item) => {
                                                return (
                                                    <tr key={item.container} className="border border-5 border-secondary bg-white">
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.loc}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.box}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            <>
                                                                <span className="d-block"> {item.container}</span>
                                                                <span> {item.line}</span>
                                                            </>
                                                        </td>                                                         
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            {item.hold_Status.length > 0 ?
                                                                <>
                                                                    <span className="d-block"> {item.hold_Status}</span>
                                                                    <span> {item.eq_Status}</span>
                                                                </>
                                                                :
                                                                <span> {item.eq_Status}</span>
                                                            }
                                                        </td>
                                                        <td className="fs-6 fw-bold cell-pad">
                                                            {item.bodyDamage.length > 0 ?
                                                                <>
                                                                    <span className="d-block">{item.bodyDamage}</span>
                                                                    <span>{item.bD_Comment ?? ''}</span>
                                                                </>
                                                                :
                                                                <span>{item.bD_Comment ?? ''}</span>
                                                            }
                                                        </td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            {item.bD_JobStatus !== null ?
                                                                <>
                                                                    <span className="d-block">{item.bD_JobStatus}</span>
                                                                    <span>{item.mD_JobStatus ?? ''}</span>
                                                                </>
                                                                :
                                                                <span>{item.mD_JobStatus ?? ''}</span>
                                                            }
                                                        </td>
                                                        <td className="fs-6 fw-bold">
                                                            {item.comments.length > 0 ?
                                                                <>
                                                                    <span className="d-block">{item.comments}</span>
                                                                    <span>{(item.mD_Comment.length > 0) ? '/' + item.mD_Comment : ''}</span>
                                                                </>
                                                                :
                                                                <span>{item.mD_Comment ?? ''}</span>
                                                            }
                                                        </td>
                                                        <td className="fs-6 fw-bold text-center cell-pad text-warning">{item.put_Back}</td>
                                                        <td className="text-center cell-pad">
                                                            <Button type="primary" className="rounded" style={{ background: "#17a2b8", borderColor: "#17a2b8", marginLeft: '0.4em' }} onClick={() => showModalHandler(item.container, item.life_Num, item.loc)}>SHIFT</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={10}>
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{onLoad ? '' : 'No data found !'}</span>
                                                </td>
                                            </tr>
                                    }
                            </tbody>
                                <tfoot className="bg-secondary"></tfoot>
                            </table>
                        </div>
                    </div>
                }
                {
                    isLoading &&
                    <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                    </div>
                }

                <Modal
                    visible={open}
                    title="Click on Shifted to confirm."
                    destroyOnClose={true}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    centered
                    width={500}
                    footer={[                        
                        <Button key="submit" type="primary" loading={loading} className="rounded" onClick={handleOk} style={{ background: "#28a745", borderColor: "#28a745" }}>
                            Shifted
                        </Button>,
                        <Button key="back" className="rounded" onClick={handleCancel} style={{ background: "#eca52b", borderColor: "#eca52b" }}>
                            Close
                        </Button>
                    ]}
                >
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-3 fw-bold fs-5">
                                Location
                            </div>
                            <div className="col-9">
                                <Input size="large" placeholder=""
                                    value={newLocation}
                                    onChange={(e) => setNewLocation(e.target.value.toUpperCase())}
                                    ref={locationRef}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            shiftHandler(e.target.value);                                            
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </>  
    );
};

export default Shift