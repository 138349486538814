import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { Form } from "react-bootstrap";
import { Form as FormAntd, Button, Input, Row, Col, Divider, Modal, notification, Popconfirm, message, Switch, Select, Tooltip, Space } from 'antd';
import 'antd/dist/antd.css';
import 'font-awesome/css/font-awesome.min.css';
import { QuestionCircleOutlined, EditOutlined, FormOutlined, ReloadOutlined } from '@ant-design/icons';

import authHeader from "../services/auth-header";
import AuthService from "../services/auth.service";

import _db from "../services/data-service";

const ptiTypes = [
    { id: "Fuel Only", designation: "Fuel Only" },
    { id: "PTI / Fuel", designation: "PTI / Fuel" },
    { id: "PTI Already Done", designation: "PTI Already Done" },
    { id: "PTI Not Performed", designation: "PTI Not Performed" },
    { id: "PTI Not Required", designation: "PTI Not Required" },
    { id: "Service", designation: "Service" }
];
const NOT_DONE = "NOT DONE";

const Fuel = () => {
    //const locat = useLocation();
    //const sTerminal = locat.state?.sel;
    //const sLocation = locat.state?.lc;

    let search = window.location.search; // const search = locat.search;
    let params = new URLSearchParams(search);
    let sTerminal = params.get('t');
    let sLocation = params.get('l');

    //const { sTerminal, sLocation } = useParams();
    //console.log(locat, locat.search, sTerminal, sLocation)
    //const [link, setLink] = useState('/dashboard');
    
    const { Option } = Select;
    const user = AuthService.getCurrentUser();
    const [onlineUser, setOnlineUser] = useState({ userCode: '', userName: '', password: '', lines: '', class: '', remote: '', terminal: '', allowPti:false });
    const [terminals, setTerminals] = useState([]);
    const [location, setLocation] = useState(sLocation);

    const [isLoading, setIsLoading] = useState(false);
    const [onLoad, setOnLoad] = useState(true);
    const [data, setData] = useState([]);
    const [container, setContainer] = useState('');
    const qtyRef = useRef(null);

    const [qty, setQty] = useState('');
    const [pumpCurrentIndex, setPumpCurrentIndex] = useState(0);

    const [pump1, setPump1] = useState(false);
    const [pump2, setPump2] = useState(false);

    const [cancelQty, setCancelQty] = useState(''); 
    const [ptiTypeData, setPtiTypeData] = useState({ cntrNum: '', lifeNum: '', ptiDone: '', inPtiType: '', ptiType: '' });

    /* --- MESSAGE MODAL --- */
    const destroyAll = () => {
        Modal.destroyAll();
    };

    const showWarning = () => {
        Modal.error({
            title: 'Error',
            content: (
                <div>
                    <p>Fuel has been poured and will be assigned to this Genset.</p>
                    <p>Use the Cancel function to transfer or call your administrator.</p>
                </div>
            ),
            onOk() { destroyAll() },
        });
    };
    /* --- END MSG MODAL ---*/

    /* -------- MODAL --------- */
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (mode) {
            const isFormValid = validateForm();
            if (isFormValid) {
                tptiId === 0 ? await handleSaveTPtiPart() : await handleUpdateTPtiPart();
                await getData();
                setLoading(false);
                setOpen(false);
            }
            else {
                setLoading(false);
            }
        }
        else {
            setQty(pumpCurrentIndex);

            tptiId === 0 ? await handleSaveTPtiPart() : await handleUpdateTPtiPart();
            await getData();
            setLoading(false);
            setOpen(false);
        }
    }

    const handleCancel = () => {
        if (!mode) {
            //alert("relire");
            let newIndex = 0; // TODO: Nouvelle valeur lue sur la pompe
            if (newIndex !== pumpCurrentIndex) {
                showWarning();
                //handleOk();
            }
        }
        setLoading(false);
        setOpen(false);
        //setQty(() => { return '' });
        setQty('');
        setContainer('');   
        setErrors({ qty: '' });
    }
    /* ------ END MODAL --------- */

    /* ------ NOTIFICATION ------*/
    const openNotificationWithIcon = (type, description) => {
        notification[type]({
            message: 'Hunt',
            description: `${description}`, 
            duration: 4
        });
    };
    /*----- END NOTIFICATION ---- */

    /* -------- CANCEL MODAL --------- */
    const [loadingCM, setLoadingCM] = useState(false);
    const [openCM, setOpenCM] = useState(false);
    const [lineQty, setLineQty] = useState(0);

    const showCancelModal = (cntr, lineQuantity) => {
        setContainer(cntr);
        setOpenCM(true);
        setLineQty(lineQuantity);
        setCancelQty(lineQuantity);
    };

    const handleOkCM = async (e) => {
        e.preventDefault();
        setLoadingCM(true);

        const isFormValid = validateFormCM();
        if (isFormValid) {
            await handleCancelTPtiPart();
            await getData();
            setLoadingCM(false);
            setOpenCM(false);
            setDestination('');
            setCancelQty('');
            setErrorsCM({ destination: '', cancelQty: '' });
            setLineQty(0);
        }
        else {
            setLoadingCM(false);
        }        
    }

    const handleCancelCM = () => {        
        setLoadingCM(false);
        setOpenCM(false);
        setContainer('');
        setErrorsCM({ destination: '' });
        setDestination('');
        setLineQty(0);
    }
    /* ------ END CANCEL MODAL --------- */

    /* -------- UPDATE PTI Type MODAL --------- */
    const [loadingUTM, setLoadingUTM] = useState(false);
    const [openUTM, setOpenUTM] = useState(false);
    const [linePtiType, setLinePtiType] = useState('');

    const showUpdatePtiModal = (cntrNum, lifeNum, ptiDone, inPtiType, ptiType) => {
        setPtiTypeData({ cntrNum, lifeNum, ptiDone, inPtiType, ptiType });
        /*console.log('LINE', cntrNum, lifeNum, ptiDone, inPtiType, ptiType)*/
        setContainer(cntrNum);
        setOpenUTM(true);
        setLinePtiType(ptiType ? ptiType : inPtiType);
        //setLinePtiType(ptiType);
    };

    const handleOkUTM = async (e) => {
        e.preventDefault();
        setLoadingUTM(true);

        const isFormValid = validateFormUTM(); 
        if (isFormValid) {
            await handleUpdatePtiType();
            await getData();
            setLoadingUTM(false);
            setOpenUTM(false);
            setPtiTypeData({ cntrNum: '', lifeNum: '', ptiDone: '' , inPtiType: '',  ptiType: '' });
            setLinePtiType('');
            setErrorsUTM({ linePtiType: '' });
        }
        else {
            setLoadingUTM(false);
        }
    }

    const handleCancelUTM = () => {
        setLoadingUTM(false);
        setOpenUTM(false);
        setContainer('');
        setErrorsUTM({ linePtiType: '' });
        setPtiTypeData({ cntrNum: '', lifeNum: '', ptiDone: '', inPtiType: '', ptiType: '' });
        setLinePtiType('');
    }
    /* ------ END Edit PTI Type MODAL --------- */


    const [selected, setSelected] = useState(sTerminal);
    const handleChange = e => {
        setSelected(e.target.value);
    }

    async function populateTerminals() {
        const { data: response } = await _db.getTerminals();
        setTerminals(response);

        //const response = await _db.getTerminals();
        //setTerminals(response.data);
    }

    //async function getUserTerminal() {
    //    const { data: response } = await _db.getCurrentUserTerminal();
    //    setSelected(response);
    //};

    const getUserData = async () => {
        const { data: userData } = await _db.getCurrentUserData(); 
        setOnlineUser({ ...userData });

        if (sTerminal) {
            setSelected(sTerminal);
        } else
        if (userData.terminal !== '') {
            setSelected(userData.terminal);
        }
    }

    //const getUserTerminal = async () => {
    //    const { data: response } = await _db.getCurrentUserTerminal();
    //    if (sTerminal) {
    //        setSelected(sTerminal);
    //    } else
    //    if (response !== '') {
    //        setSelected(response);
    //    }     
    //}

    useEffect(() => {
        populateTerminals();
        //getUserTerminal();
        getUserData();
    }, []);

    //useEffect(() => {
    //    setLink(`/dashboard/${selected}/${location}`);
    //    //setLink(`/dashboard/fuel?terminal=${selected}&location=${location}`);
    //}, [selected, location]);

    /* ************* FORM VALIDATION **************** */
    const [errors, setErrors] = useState({qty:''});
    const validateForm = () => {
        let valid = true;
        const errorsCopy = { ...errors };

        if (!qty.trim()) {
            errorsCopy.qty = 'Quantity is required!';
            valid = false;
        } 
        else if (!/^\d*$/.test(qty)) { /*/^-?\d*(\.\d*)?$/ /^[0-9]{1,3}$/ */
            errorsCopy.qty = 'Quantity is invalid!';
            valid = false;
        }
        else if (Number(qty) <= 0 || Number(qty) >= 1000) {
            errorsCopy.qty = 'Quantity is out of range!';
            valid = false;
        }
        else if (tptiId !== '' && (lineUQty + Number(qty) >= 1000)) {
            errorsCopy.qty = 'Final quantity is out of range!';
            valid = false;
        }
        else {
            errorsCopy.qty = '';
        }

        setErrors(errorsCopy);
        qtyRef.current.focus();
        return valid;
    }
    /* ************* END FORM VALIDATION **************** */

    /* ************* CANCEL MODAL FORM VALIDATION **************** */
    const [errorsCM, setErrorsCM] = useState({ destination: '', cancelQty:'' });
    const validateFormCM = () => {
        let valid = true;
        const errorsCMCopy = { ...errorsCM };

        if (!destination.trim()) {
            errorsCMCopy.destination = 'Genset is required!';
            valid = false;
        }
        //else if ((data.filter(w => w.equiP_NUM === destination))[0].qty + lineQty >= 1000) {
        //    errorsCMCopy.destination = 'Quantity is out of range!';
        //    valid = false;
        //}
        else {
            errorsCMCopy.destination = '';
        }

        if (!cancelQty.toString().trim()) {
            errorsCMCopy.cancelQty = 'Quantity is required!';
            valid = false;
        }
        else if (!/^\d*$/.test(Number(cancelQty))) { /*/^-?\d*(\.\d*)?$/ /^[0-9]{1,3}$/ */
            errorsCMCopy.cancelQty = 'Quantity is invalid!';
            valid = false;
        }
        else if (Number(cancelQty) > lineQty) {
            errorsCMCopy.cancelQty = `Quantity must not be greater than ${lineQty}!`;
            valid = false;
        }
        else if (destination.trim() && (data.filter(w => w.equiP_NUM === destination)[0].qty + Number(cancelQty) >= 1000)) {
            //alert(data.filter(w => w.equiP_NUM === destination)[0].qty + Number(cancelQty));

            errorsCMCopy.cancelQty = 'Final quantity is out of range!';
            valid = false;
        }
        else {
            errorsCMCopy.cancelQty = '';
        }

        setErrorsCM(errorsCMCopy);
        //destination.current.focus();
        return valid;
    }
    /* ************* END CANCEL MODAL FORM VALIDATION **************** */

    /* ************* UPDATE PTI TYPE MODAL FORM VALIDATION **************** */
    const [errorsUTM, setErrorsUTM] = useState({ linePtiType: '' });
    const validateFormUTM = () => {
        let valid = true;
        const errorsUTMCopy = { ...errorsUTM };

        if (linePtiType === null) {
            errorsUTMCopy.linePtiType = 'PTI type is required!';
            valid = false;
        }
        else {
            errorsUTMCopy.linePtiType = '';
        }

        setErrorsUTM(errorsUTMCopy);
        return valid;
    };
    /* ************* END UPDATE PTI TYPE MODAL FORM VALIDATION **************** */

    const handleSaveTPtiPart = async () => {
        const url = "/api/posttptipart";
        const oData = { "cntrNum": container, "qty": qty, "mecanic": user.userName };

        await _db.post(url, oData, { headers: authHeader() })
                 .then((reponse) => {
                    handleCancel();
                    openNotificationWithIcon('success', 'Fueled succesfully!');
                 })
                 .catch((error) => {
                    console.log(error)
                    openNotificationWithIcon('error', error)               
                 })
    };

    const handleUpdateTPtiPart = async () => {
        const url = `/api/puttptipart/${tptiId}`
        const oData = { "id": tptiId, "cntrNum": container, "qty": qty, "mecanic": user.userName };

        await _db.put(url, oData, { headers: authHeader() })
                .then((reponse) => {
                    handleCancel();
                    openNotificationWithIcon('success', 'Added succesfully!');
                })
                .catch((error) => {
                    console.log(error)
                    openNotificationWithIcon('error', error)  
                    setQty('');
                    setErrors({ qty: '' });
                })
    };

    const handleCancelTPtiPart = async () => {
        const url = "/api/canceltptipart";

        //const oData = { "cntrNum": container, "qty": qty, "mecanic": user.userName };

        //const response = await _db.get(url, { headers: authHeader(), params: { terminal: selected, location: location } });
        //await _db.post(url, oData, { headers: authHeader(), params: { source: container, destination: destination } })
        await _db.get(url, { headers: authHeader(), params: { source: container, destination: destination, quantity: cancelQty, userName: user.userName } })
            .then((reponse) => {
                handleCancelCM();
                openNotificationWithIcon('success', 'Canceled succesfully!');
            })
            .catch((error) => {
                console.log(error)
                openNotificationWithIcon('error', error)
            })
    };

    const handleUpdatePtiType = async () => {
        const url = `/api/updateptitype/${container}`; 
        const oData = { ...ptiTypeData, ptiType: linePtiType, mecanic: user.userName };
        console.log ('ODATA', oData)
       /* const oData = { "cntrNum": container, "ptiType": linePtiType, "mecanic": user.userName };*/

        await _db.post(url, oData, { headers: authHeader() })
            .then((reponse) => {
                handleCancelUTM();
                openNotificationWithIcon('success', 'PTI type updated succesfully!');
            })
            .catch((error) => {
                setErrorsUTM({ linePtiType: '' });
                console.log(error)
                openNotificationWithIcon('error', error)
            })
    };

    //Load on startup
    useEffect(() => {
        if (sLocation) getData();
    }, []);

    //Set focus on Qty input text...
    useEffect(() => {
        if (qty.current) {
            qty.current.focus();
        }
    }, [open]);

    const getData = async () => {
        try {
            setIsLoading(true);
            setOnLoad(false);

            let url = "/api/DashboardFuelGetGenset";
            //let URL = `/api/FuelGetGenset?terminal=${terminal}&location=${location}`;
            console.log('fuel', authHeader());
            const response = await _db.get(url, { headers: authHeader(), params: { terminal: selected, location: location } });

            console.log('data', response.data)

            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    //const pump1OnClick = (e) => {
    //    //e.currentTarget.disabled = true;
    //    setPump1(true);
    //    setPump2(false);
    //}

    //const pump2OnClick = (e) => {
    //    //e.currentTarget.disabled = true;
    //    setPump2(true);
    //    setPump1(false);
    //}

    /* SEARCH */
    const locationChangeHandler = (e) => {
        //console.log('UD', ud)
        setLocation(e.target.value.toString().toUpperCase());
    }
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && e.target.value.length > 0) {
            getData()
        }
    }
    /* END SEARCH */

    /*   POP CONFIRM   */
    const [tptiId, setTptiId] = useState(null);
    const [openPC, setOpenPC] = useState(false);
    const [lineUQty, setLineUQty] = useState(0);

    const confirm = () => {
        setOpenPC(false);
        showModal();
        //message.success('Next step.');
    };
    const cancel = () => {
        setOpenPC(false);
        //message.error('Click on cancel.');
    };

    const handleVisible = (cntr, visible, qty, id) => {
            setTptiId(id);
            setContainer(cntr);
            setLineUQty(qty);

            if (!qty) {
                confirm();
            }
            else {
                if (!mode) {                
                    if (pump1) {
                        readModBusClient(1); 
                    }
                    else if (pump2){
                        readModBusClient(2); 
                        }
                }              
                setOpenPC(visible);
            }
    };
    /* END POP CONFIRM */

    const connectModBusClient = async (pump) => {
        let url = "/api/ConnectToPump";
        await _db.get(url, { headers: authHeader(), params: { pumpNumber: pump } })
                .then((reponse) => {
                    if (pump === 1) {
                        setPump1(true);
                        setPump2(false);
                    } else {
                        setPump2(true);
                        setPump1(false);
                    }              
                    //openNotificationWithIcon('success', 'connected succesfully!');
                })
                .catch((error) => {
                    console.log(error);
                    openNotificationWithIcon('error', error)
                })
    };

    const readModBusClient = async (pump) => {
        let url = "/api/ReadPumpIndex";
        await _db.get(url, { headers: authHeader(), params: { pumpNumber: pump } })
            .then((reponse) => {
                setPumpCurrentIndex(reponse.data);
            })
            .catch((error) => {
                console.log(error);
                setPumpCurrentIndex(0);
            })
    };

    const [mode, setMode] = useState(true); //Mode "manual" or not 
    const handleChangeMode = (checked) => {
        setMode(checked);
    };

    const [destination, setDestination] = useState('');

    return (
        <>
            <div className="border border-5 border-secondary overflow bg-secondary" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>

                <div className="container bg-secondary">
                    <div className="row">
                        <div className="col-md-4 my-2">
                            <span className="fs-4 fw-bold text-white">FUEL TRANSACTION</span>
                        </div>                       
                        <div className="col-md-3 my-2 offset-md-2">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Terminal</Form.Label>
                                    <Form.Select value={selected || ''} inline="true" className="fs-7 terminal-select" size="sm" onChange={(e) => handleChange(e)}>
                                        {/* <option value="ALL">All Terminals</option> */}
                                        {terminals.map((terminal) => (<option key={terminal.terminalCode} value={terminal.terminalCode}>{terminal.terminalCode}</option>))}
                                    </Form.Select>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 my-2 offset-md-1">
                            <div className="row">
                                <div className="fs-6 fw-bold d-flex justify-content-end">
                                    <Form.Label className="me-2 mt-1 text-white">Location</Form.Label>
                                    <Form.Control
                                    className="fs-7"
                                    maxLength={3}
                                    size="sm"
                                    type="text"
                                    name="location"
                                    value={location || ''}
                                    onChange={locationChangeHandler}
                                    onKeyPress={handleKeyPress}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container bg-white">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2 pt-3">
                            {user && user.userName}/{new Date().toDateString() /*getDate(new Date())*/}
                        </div>
                        <div className="col-md-5 col-sm-12 my-2">
                            <Button type="primary" className="rounded" disabled={!location} onClick={getData}>REFRESH</Button>
                            <Button type="primary" className="rounded ms-2 mt-2" onClick={() => connectModBusClient(1) } disabled={pump1}>PUMP 1</Button>
                            <Button type="primary" className="rounded ms-2 mt-2" onClick={() => connectModBusClient(2) } disabled={pump2}>PUMP 2</Button>
                        </div>
                        <div className="col-md-1 col-sm-6 my-2 text-md-center">
                            <Button type="primary" className="rounded ms-2 mt-2" style={{ background: "#eca52b", borderColor: "#eca52b" }} onClick={() => alert("Coming soon !")}>ColinScan</Button>
                        </div>
                       
                        <div className="col-md-3 col-sm-6 my-2 text-md-end">
                            <Link to={{ pathname: "/dashboard", search: `?t=${selected}&l=${location}`, /*state: { sel: selected, lc: location }*/ }}>
                                <Button type="primary" className="rounded ms-2 mt-2" disabled={!location}>BACK TO DASHBOARD</Button>
                            </Link>   
                        </div>
                    </div>
                    <div className="border border-2 border-secondary bg-secondary">
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-12 my-2 pt-1">
                            Manual Mode ON/OFF <Switch defaultChecked onChange={handleChangeMode} />                      
                        </div>                   

                        {
                            (pump1 || pump2) &&
                            <div className="col-md-9 col-sm-12 my-2 text-md-end my-2 pt-1 fw-bold" style={{ color: "green" }}>
                                    Connected to PUMP {pump1 ? "1" : "2"} {/*- Index : {pumpCurrentIndex}*/}
                            </div>
                        }
                    </div>
                    
                </div>

            </div>
            <div className="content">
                {
                    !isLoading &&
                    <div className="pt-0 mb-2">
                        <div className="border border-5 border-secondary overflow bg-secondary">
                            <table className="" aria-labelledby="tabelLabel">
                            <thead>
                                <tr className="bg-white border border-5 border-secondary" style={{ height: '40px' }}>
                                    <th width="50" className="text-center cell-pad">LOC</th>
                                        <th width="130" className="text-center">GENSET NO.</th>
                                        <th width="100" className="text-center">SHIP.LINE</th>
                                        <th width="" className="text-center">START</th>
                                        {/*<th width="200" className="text-center">END</th>*/}
                                        <th width="" className="text-center">FUEL</th>
                                        <th width="50" className="text-center">CANCEL</th>
                                        <th width="250" className="text-center">PTI TYPE</th> 
                                            {
                                                onlineUser.allowPti && <th width="" className="text-center"></th>
                                        }                                                                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data && data.length > 0 ?
                                            data.map((item) => {
                                                return (
                                                    <tr key={item.equiP_NUM} className="border border-5 border-secondary bg-white">
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.location}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.equiP_NUM}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.line}</td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            <Popconfirm
                                                                title={<><span>Do you want to add more Fuel to the existing quantity for genset </span><span className="fw-bold">{item.equiP_NUM}</span>?</>}
                                                                onConfirm={() => confirm()}
                                                                onCancel={() => cancel()}
                                                                visible={container === item.equiP_NUM && openPC}
                                                                okText="Yes"
                                                                cancelText="No"
                                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                                            >                                                                
                                                                <Button type="primary" className="rounded" disabled={!mode && !(pump1 ? !pump2 : pump2)} onClick={() => handleVisible(item.equiP_NUM, true, item.qty, item.id) }>
                                                                    START
                                                                </Button>
                                                            </Popconfirm>
                                                        </td>
                                                        {/*<td className="fs-6 fw-bold text-center cell-pad">                                                            */}
                                                        {/*    <Button type="primary" className="rounded" disabled style={{ marginLeft: '0.4em' }}>END</Button>*/}
                                                        {/*</td>*/}
                                                        <td className="fs-6 fw-bold text-center cell-pad">{item.qty}</td>
                                                        <td className="text-center cell-pad">
                                                            <Button type="primary" className="rounded" disabled={item.qty === 0} style={{ marginLeft: '0.4em' }} onClick={() => showCancelModal(item.equiP_NUM, item.qty)} >CANCEL</Button>
                                                        </td>
                                                        <td className="fs-6 fw-bold text-center cell-pad">
                                                            {(item.ptI_TYPE) ? item.ptI_TYPE : item.iN_PTI_TYPE} {/*si PTI Type existe dans e_g_insp, affiche le sinon affiche le PTI Type initial (celui qui est dans le IN e_g_in ou lease_in) */}                                                                                                                       
                                                        </td>                                                        
                                                        {
                                                            onlineUser.allowPti &&
                                                            <td className={"fs-6 fw-bold text-center cell-pad"} >
                                                            {      
                                                                /* Si PTI Type n'existe pas dans e_g_insp on peut changer le PTI Type si le pti est "not done" */
                                                                (!item.ptI_TYPE && item.ptI_DONE === NOT_DONE) &&                                                            
                                                                <Space>
                                                                    <Tooltip title="Update PTI type">
                                                                        <span>
                                                                                <FormOutlined style={{ cursor: 'Pointer' }} onClick={() => showUpdatePtiModal(item.equiP_NUM, item.lifE_NUM, item.ptI_DONE, item.iN_PTI_TYPE, item.ptI_TYPE)} />
                                                                        </span>
                                                                    </Tooltip>
                                                                </Space>                                                            
                                                            }
                                                        </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={6}>
                                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{onLoad ? '' : 'No data found !'}</span>
                                                </td>
                                            </tr>
                                    }
                            </tbody>
                                <tfoot className="bg-secondary"></tfoot>
                            </table>
                        </div>
                    </div>
                }
                {
                    isLoading &&
                    <div className="border border-5 border-secondary overflow" style={{ borderColor: 'gray', padding: '0px', width: '100%', textAlign: 'center', fontSize: '32px' }}>
                        <i className="fa fa-spinner fa-spin" style={{ paddingTop: '10px' }}></i>
                    </div>
                }
            </div>

            <Modal
                visible={open}
                title={<><span>Enter fuel quantity for </span><span className="fw-bold">{container}</span></>} 
                destroyOnClose={true}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                width={500}
                footer={[                    
                    <Button key="submit" className="rounded" type="primary" loading={loading} onClick={handleOk} style={{ background: "#28a745", borderColor: "#28a745" }}> {/**/}
                        Save
                    </Button>,
                    <Button key="back" className="rounded" onClick={handleCancel} style={{ background: "#eca52b", borderColor: "#eca52b" }}>
                        Exit
                    </Button>
                ]}
            >
                <div className="container">
                    <div className="row mb-4">
                        {
                            (mode) ? 
                            <> 
                            <div className="col-3 fw-bold fs-5">
                                Fuel # 
                            </div>
                            <div className="col-9">                          
                                <Input size="normal" placeholder="" maxLength={3} style={{ width: 250, borderRadius:0, textAlign: 'right' }}
                                    name="qty"
                                    className={`form-control ${errors.qty ? 'is-invalid' : ''}`}
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                    ref={qtyRef}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleOk(e);
                                        }
                                    }}
                                    />                          
                                {errors.qty && <div className="invalid-feedback">{errors.qty}</div>}                            
                            </div>  
                            </> 
                        :
                            <div className="col-12 fw-bold fs-5">
                                    Current index : {pumpCurrentIndex }
                            </div>
                        }                        
                        
                    </div>
                </div>
            </Modal>

            <Modal
                visible={openCM}
                title={<><span>Cancel fuel quantity for </span><span className="fw-bold">{container}</span></>}
                destroyOnClose={true}
                onOk={handleOkCM}
                onCancel={handleCancelCM}
                centered
                width={475}
                footer={[
                    <Button key="submit" className="rounded" type="primary" loading={loadingCM} onClick={handleOkCM} style={{ background: "#28a745", borderColor: "#28a745" }}> {/**/}
                        Save
                    </Button>,
                    <Button key="back" className="rounded" onClick={handleCancelCM} style={{ background: "#eca52b", borderColor: "#eca52b" }}>
                        Exit
                    </Button>
                ]}
            >
                <div className="container">
                    <div className="row mb-2">                      
                        <div className="col-3 fw-bold fs-5">
                            Genset #
                        </div>
                        <div className={`col-9 ${errorsCM.destination ? 'ant-select-error' : ''}`}>
                            <Select
                                showSearch
                                style={{ width: 250 }}
                                placeholder="Select a genset"
                                name="destination"
                                className={`p-0 ${errorsCM.destination ? 'is-invalid' : ''}`}
                                //onSearch={onSearch}
                                onChange={ value => { setDestination(value); }}
                            >
                                {/*<Option value={undefined}></Option>*/}
                                {data.filter(w => w.equiP_NUM !== container)
                                     /*.sort((a, b) => a.equiP_NUM - b.equiP_NUM)*/
                                     .map(genset => (<Option key={genset.equiP_NUM} value={genset.equiP_NUM}>{genset.equiP_NUM}</Option>))}                                
                            </Select>
                            {errorsCM.destination && <div className="invalid-feedback">{errorsCM.destination}</div>}
                        </div>                               
                    </div>

                    <div className="row mb-4">
                        <div className="col-3 fw-bold fs-5">
                            Quantity 
                        </div>
                        <div className="col-9">
                            <Input size="normal" placeholder="" maxLength={3} style={{ width: 250, borderRadius:0, textAlign: 'right' }}
                                name="cancelQty"
                                className={`form-control ${errorsCM.cancelQty ? 'is-invalid' : ''}`}
                                value={cancelQty}
                                onChange={(e) => setCancelQty(e.target.value)}
                                //ref={cancelQtyRef}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        handleOkCM(e);
                                    }
                                }}
                            />
                            {errorsCM.cancelQty && <div className="invalid-feedback">{errorsCM.cancelQty}</div>}
                        </div>

                    </div>
                </div>
            </Modal>

            {/*Modal for updating PTI Type*/}
            <Modal
                visible={openUTM} //UTM = Update type Modal
                title={<><span>Update PTI type for </span><span className="fw-bold">{container}</span></>}
                destroyOnClose={true}
                onOk={handleOkUTM}
                onCancel={handleCancelUTM}
                centered
                width={475}
                footer={[
                    <Button key="submit" className="rounded" type="primary" loading={loadingUTM} onClick={handleOkUTM} style={{ background: "#28a745", borderColor: "#28a745" }}> 
                        Save
                    </Button>,
                    <Button key="back" className="rounded" onClick={handleCancelUTM} style={{ background: "#eca52b", borderColor: "#eca52b" }}>
                        Exit
                    </Button>
                ]}
            >
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-3 fw-bold fs-5">
                            Type #
                        </div>
                        <div className={`col-9 ${errorsUTM.linePtiType ? 'ant-select-error' : ''}`}>
                            <Select
                                showSearch
                                status="error"
                                style={{ width: 250, }}
                                placeholder="Select a PTI type"
                                name="linePtiType"
                                value={linePtiType}
                                className={`p-0 ${errorsUTM.linePtiType ? 'is-invalid' : ''}`}
                                //onSearch={onSearch}
                                onChange={value => { setLinePtiType(value); }}
                            >
                                {/*<Option value={undefined}></Option>*/} 
                                { ptiTypes.map(item => (<Option key={item.id} value={item.designation}>{item.designation}</Option>))}
                            </Select>
                            {errorsUTM.linePtiType && <div className="invalid-feedback">{errorsUTM.linePtiType}</div>}
                        </div>
                    </div>                    
                </div>
            </Modal>

        </>  
    );
};

export default Fuel