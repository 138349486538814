import agent from "./data-agent";
import authHeader from "./auth-header";
import { notification } from 'antd';

const baseUrl = "/api/";

function getTerminals() {
    return agent.get(`${baseUrl}getAllTerminals`, { headers: authHeader() });
}

async function getCurrentUserData() {
    return await agent.get(`${baseUrl}getUserData`, { headers: authHeader() });
}

function getCurrentUserTerminal() {
    return agent.get(`${baseUrl}getCurrentUserTerminal`, { headers: authHeader() });
}

/* ------ NOTIFICATION ------*/
const openNotificationWithIcon = (type, description) => {
    notification[type]({
        message: 'Hunt',
        description: `${description}`,
        duration: 4
    });
};
/*----- END NOTIFICATION ---- */

export default {
    get: agent.get, //axios.get
    post: agent.post, //axios.post,
    put: agent.put, //axios.put
    patch: agent.patch, //axios.patch
    delete: agent.delete, //axios.delete
    openNotificationWithIcon,
    getTerminals,
    getCurrentUserTerminal,
    getCurrentUserData
};